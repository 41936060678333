@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Teko", sans-serif;
        font-optical-sizing: auto;
        font-weight: 300..700;
        font-style: normal;
        src: url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');
    }

    h1 {
        @apply font-Teko font-extrabold text-hot-chocolate;
    }
    
    h2 {
        @apply font-Teko font-bold text-latte;
    }

    h3 {
        @apply font-Teko text-latte;
    }

    p {
        @apply text-latte;
    }
}

@layer components {

    .border {
        @apply border-hot-chocolate;
    }

    .btn {
        @apply bg-transparent border border-latte hover:border-transparent hover:bg-latte text-latte hover:text-creamer font-Teko font-semibold text-2xl px-3 pt-1 rounded;
    }

    .card {
        @apply bg-transparent rounded-lg p-6 pt-16 w-full h-full  mx-auto;
    }

}
